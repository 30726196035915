/* ****************************************************************
//
// This file is subject to the terms and conditions defined in file
// 'LICENSE.txt', which can be found at the root of this project.
//
// ****************************************************************
*/
@import "~antd/dist/antd.dark.css";

document.window {
  transition: height 0.5s;
}
#newBtn {
  border: 0px !important;
  letter-spacing: 0.15em;
  color: #666 !important;
  background: #fff !important;
  font-size: 12px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: bold !important;
}

#newBtnTwo {
  border: 0px !important;
  letter-spacing: 0.15em;
  color: #666 !important;
  background: #fff !important;
  font-size: 12px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: bold !important;
}
#root {
  height: 100%;
}

.ant-layout {
  /*height: 100%;*/
  /*background-color: #353535;*/
}

form {
    /*height: 100%;*/
    width: 100%;
}
.ant-input-password {
  border-radius: 25px !important;
}
.loginContainer {
    height: 69%;
    margin-top: 13%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    padding: 5%;
    text-align: center;
    display: block;
}

.form-group {
    height: 56px;
    margin: 8px;
    padding: 5px;
}

label {
    margin-right: 13px;
    text-align: left;
}

h1 {
    /*font-family: "Roboto";*/
    font-size: 24px;
    color: #fff;
}

.loginBtn {
    margin-top: 5%;
    width:  100px;
    border: 0px;
    color:  #000;
    background-color: #fff;
    /*font-family: "Roboto";*/
    font-size: 18px;
}

.loginContainer input {
    color: #000;
    background: #fff;
    border-color: #fff;
    border-radius: 25px;
    padding: 10px;
    border-width: 1px;
    /*font-family: "Roboto";*/
}

.splashContainer {
    z-index: 9999999;
}

/*.mainContainer {
    z-index: 9999997;
}*/

.loginMain {
    z-index: 9999998;
}

.trigger {
  width: 100%;
  padding: 0 30px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  text-align: -webkit-right;
}

.trigger:hover {
  color: #1890ff;
}
.ant-modal-footer {
  padding: 20px 32px !important;
  text-align: left !important;
  background: transparent;
  border-top: 1px solid #303030;
  border-radius: 0 0 2px 2px;
  display: flex !important;
  justify-content: space-between !important;
}
.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

/*.ant-layout-sider {
  max-width: 180px !important;
  min-width: 180px !important;
  width: 180px !important;
  flex: 0 0 180px !important;
  transition: width 0.5s;
}
*/
.site-layout {
  margin-left:  180px;
  transition: margin-left 0.3s;
}
/*
.site-layout .site-layout-background {
  background: #fff;
}*/
.site-layout-background {
  padding: 0 0 64px 0 !important;
}

.appPlayer .ant-card-extra {
  width: 25%
}

.appPlayer .player-elapsed {
  text-align: -webkit-center;
  padding-top: 4px;
  padding-left: 15px;
}

.appPlayer .duration-slider, .appPlayer .duration-slider .ant-slider-handle {
  cursor: default;
}

.appPlayer .player-duration {
  text-align: -webkit-center;
  padding-top: 4px;
  padding-left: 15px;
}

.appPlayer .player-duration-lg {
  text-align: -webkit-center;
  padding-top: 14px;
  font-size: 18px;
}

.appPlayer .player-timeline {
  height: 24px;
}

.shuffle-repeat {
  align-self:  center;
  padding-top: 14px;
  /*padding-bottom: 4px;*/
}

.playback-elements {
  transition: opacity 0.3s;
  opacity: 1;
}

.player-tabs {
  border: 1px solid black;
  position: relative;
  width: 500px;
  color: black;
  align: center;
  pointer-events: auto;
}

.invisible {
  opacity: 0;
  pointer-events: none
}
/*
.ant-alert-info {
  background-color: #177ddc;
}

.ant-alert-info .ant-alert-icon {
  color: #e6f7ff;
}
*/
.vis-canvas canvas {
  max-height:  150px;
  height: 150px;
  -webkit-transition: height 0.5s ease;
  transition: height 0.5s ease;
}

.vis-canvas .hide-canvas {
  height: 0;
}

.vis-canvas .ant-card-body {
  position: absolute;
  bottom: 12px;
  height: 24px;
  width: 100%;
  padding: 0;
  font-weight: 600;
}

.app-container {
  -webkit-transition: margin-top 0.5s ease;
  transition: margin-top 0.5s ease;
}

.menu-disabled .ant-menu-title-content a::before {
  display: none;
}

.ant-drawer-open.mixerDrawer {
  /*height: 86.2% !important;*/
  height: calc(100% - 96px) !important;
}

.mixerDrawer .ant-drawer-body {
  padding: 0;
}


/* Loading styles */

#loadingScreen {
    width: 100%;
    height: 100%;
    background-color: #353535;
}

#loadingScreen {
    position: relative;
    background-color: #353535;
    left: 0;
    color: rgba(255, 255, 255, 0.56);
    text-align: center;
    line-height: 10;
    font-weight: 400;
    font-family: arial, sans-serif;
}

#loadingScreen .spinner {
    content: "";
    position: relative;
    height: 2px;
    background: #fff;
    top: 20%;
    left: 0;
    width: 100%;
    animation: 1.2s loading infinite;
    transform-origin: 100% 0;
}
@keyframes loading {
    50% {
        transform: scalex(0);
    }
    51% {
        transform: translatex(-100vw);
    }
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
  
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

::-webkit-scrollbar-button {
  background-color: slategrey;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 16px;
  width: 1em;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-button:horizontal:increment {
  background-image: url(https://dl.dropboxusercontent.com/u/55165267/icon2.png);
}

::-webkit-scrollbar-button:end:increment {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}
 

::-webkit-scrollbar-button:start:decrement {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==)
}

.App {
  padding: 16px;
}
