#scope-container {
	border: 1px solid rgb(48, 48, 48);
	max-width: 100%;
}

#scope-canvas {
/*    border-radius: 50%;*/
/*	width: 600px;*/
	max-width: 100%;
	height: auto;
/*	margin-left: 7px;*/
	border: 1px solid rgb(48, 48, 48);
}