/* ****************************************************************
//
// This file is subject to the terms and conditions defined in file
// 'LICENSE.txt', which can be found at the root of this project.
//
// ****************************************************************
*/
.harmonics-array .ant-col-2 {
	/*width: calc(98% - 10px);*/
	/*width: 95%;*/
}

.harmonics-array .ant-col-2:first-child {
	/*margin-right:  2px;*/
}

.harmonics-array .ant-col-2:not(:first-child) {
	/*margin-left:  2px;*/
}
div.ant-modal-wrap > div.panoModal > div.ant-modal-content > div.ant-modal-body > div > div, .panoModal > div.ant-modal-content > div.ant-modal-body > div > div {
	height: 100% !important;
	width: 100% !important;
}
.image-capture img {
	max-width: 100%;
	height: auto;
}

.capture-preview {
}

.note-label::before {
	content: " ";
	padding-left: 33%;
}

#vis-canvas {
	width: 100%;
	height: 120px;
	outline: 1px solid grey;
}

#shiny-container {
	border: 1px solid rgb(48, 48, 48);
	/*	text-align: center;*/
	/*	writing-mode: vertical-lr;*/
	/*	-webkit-writing-mode: vertical-lr;*/
	display: flex;
	justify-content: center;
	align-items: center;
}

#shiny-canvas {
	border-radius: 50%;
	width: 235px;
	max-width: 100%;
	height: auto;
	/*margin-top: 7px;
	margin-left: 7px;*/
}

.binaural-frequency {
	align-self: end;
}

.vectorscope-canvas {
	max-width: 100%;
	height: auto;
}

@media screen and (max-width: 665px) {
	.harmonics-array.harmonics-cell {
		display: none;
	}
}

@media screen and (min-width: 664px) {
	.harmonics-array.fundamental-cell {
		display: none;
	}
}

#newBtn {
	border: 0px !important;
	letter-spacing: 0.15em;
	color: #666 !important;
	background: #fff !important;
	font-size: 12px !important;
	font-family: "Montserrat", sans-serif !important;
	font-weight: bold !important;
}

#newBtnTwo {
	border: 0px !important;
	letter-spacing: 0.15em;
	color: #666 !important;
	background: #fff !important;
	font-size: 12px !important;
	font-family: "Montserrat", sans-serif !important;
	font-weight: bold !important;
}
.ant-input {
	border-radius: 25px !important;
}
.ant-btn-primary {
	/*	background-color: #fff !important;*/
}

.ant-btn-default {
	background-color: #ccc !important;
	color: #000 !important;
}
.ant-alert-info {
	justify-self: center !important;
}
.buttonRow {
	justify-content: space-between !important;
	max-width: 75% !important;
	margin: 15px 0px !important;
}
.bottomRow {
	/*	position: fixed;*/
	bottom: 0px;
	background: #000;
	width: 100%;
	display: block !important;
	background: #000;
	height: auto;
	z-index: 999999;
	border-width: 1px 1px 1px 1px;
	border-color: #fff;
	border-style: solid;
	width: 100%;
	margin: 0px;
	left: 0px;
	padding: 10px;
}
/*.ant-modal-header {
	color: #60147e !important;
	font-family: "Montserrat", sans-serif !important;
	font-size: 13px;
	font-weight: bold !important;
	background: 0% 0% / auto auto linear-gradient(180deg, #a4eaff 13%, #a4eaff 86%) !important;
}*/
/*.ant-modal-title {
	color: #60147e !important;
	font-family: "Montserrat", sans-serif !important;
	font-size: 16px;
	font-weight: bold !important;
	text-transform: capitalize;
}*/
.ant-modal-body {
	background: #242424;
}
label {
	margin: 0px !important;
	background-color: transparent !important;
	border-radius: 0px !important;
}

.binaural-frequency {
	align-self: end;
}

@media (max-width: 767px) {
	#mobileHarmonicsRow {
		margin-top: 0px !important;
		font-size: 10px !important;
		font-weight: 600 !important;
		display: flex !important;
		flex-direction: column !important;
		width: 50%;
	}
	#mobileHarmonicsColumn {
		max-width: 100% !important;
		min-height: 20px;
	}
	.harmonics-array .harmonics-cell {

	}
	#harmonicsWrapper {
		font-size: 10px;
		font-weight: 600;
		display: flex !important;
		flex-direction: row;
		width: 100% !important;
		margin-bottom: 30px;
	}
	#shiny-container {
		min-height: 230px !important;
	}
	.harmonics-array {
		display: block !important;
	}
}

.screenshot {
	float: right;
}

.StripeElement {
	background-color: transparent !important;
	width: 100%;
}

.qr-code-container canvas {
	background-color: #ffffff;
	padding: 8px;
}